import { Component, HostListener, ViewChild, ElementRef, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonService } from 'src/app/services/common.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { formatDistanceToNow } from 'date-fns';

interface ChatMessage {
  text: string;
  type: 'user' | 'bot';
  timestamp: Date;
}

@Component({
  selector: 'app-chat-bot',
  templateUrl: './chat-bot.page.html',
  styleUrls: ['./chat-bot.page.scss'],
})
export class ChatBotPage implements OnInit {
  @ViewChild('messageInput', { static: false }) messageInput?: ElementRef;
  @ViewChild('chatContainer') private chatContainer: ElementRef;
  newMessage: string = '';
  isChatOpen: boolean = false;
  isLoading: boolean = false;
  isError: boolean = false;
  form!:FormGroup;
  showHelpCard = true;
  messages: ChatMessage[] = []; // Array to store messages
  loading = false; // Variable to track loading state
  constructor(private http: HttpClient,
    private commonService:CommonService,
    private fb: FormBuilder
  ) {}

  ngAfterViewInit() {
    try {
      this.scrollToBottom();
    } catch (err) {
      console.error('Could not scroll to the bottom of the chat:', err);
    }
  }
  ngOnInit() {
    this.form = this.fb.group({
      message: ['', [Validators.required, Validators.minLength(1)]]
    });
    // this.timeOutForClosingPopup();
  }
  // chatSubmit() {
  //   if (this.form.invalid) {
  //     console.log('Form is not valid', this.form.value);
  //     return;
  //   }
  //   const userMessage: ChatMessage = {
  //     text: this.form.value.message,
  //     type: 'user',
  //     timestamp: new Date()
  //   };
  //   this.messages.push(userMessage);
  //   const formData = {
  //     question: this.form.value.message
  //   };
  //   this.loading = true; // Show loading indicator
  //   this.commonService.chatBotData(formData).subscribe(
  //     (res: any) => {
  //       const botMessage: ChatMessage = {
  //         text: res.answer,
  //         type: 'bot',
  //         timestamp: new Date()
  //       };
  //       const hasNewLine = botMessage.text.includes("\n");
  //       if(hasNewLine){
  //         const formattedMessage: ChatMessage = {
  //           ...botMessage, // Spread the original botMessage properties
  //           text: this.formatResponse(botMessage.text) // Replace the text property with the formatted text
  //         };
  //         this.messages.push(formattedMessage);
  //       } else {
  //         this.messages.push(botMessage);
  //       }
  //       this.loading = false; // Hide loading indicator
  //     },
  //     (error:any) => {
  //        if(error){
  //         this.isError = true;
  //        }
  //       this.loading = false; // Hide loading indicator
  //     }
  //   );
  //   this.form.reset(); // Reset the form after submission
  // }
  // toggleChat() {
  //   this.isChatOpen = !this.isChatOpen;
  // }

  chatSubmit() {
    if (this.form.invalid) {
        console.log('Form is not valid', this.form.value);
        return;
    }
    const userMessage: ChatMessage = {
        text: this.form.value.message,
        type: 'user',
        timestamp: new Date()
    };
    this.messages.push(userMessage);
    const formData = {
        question: this.form.value.message
    };
    this.loading = true; // Show loading indicator
    this.commonService.chatBotData(formData).subscribe(
        (res: any) => {
            // Format the bot's response before adding it to messages
            const formattedResponse = this.formatResponse(res.answer);
            const botMessage: ChatMessage = {
                text: formattedResponse,
                type: 'bot',
                timestamp: new Date()
            };
            this.messages.push(botMessage);
            this.loading = false; // Hide loading indicator
        },
        (error: any) => {
            if (error) {
                this.isError = true;
            }
            console.error('Error occurred----:', error);
            this.loading = false; // Hide loading indicator
        }
    );
    this.form.reset(); // Reset the form after submission
}

  toggleChat() {
    this.isChatOpen = !this.isChatOpen;
    this.showHelpCard = false;
  }

  refreshChat() {
    this.messages = [];
  }
  
//   formatResponse(response: string): string {
//     // Replace \n with <br> and make text between ** bold
//     let formattedResponse = response.replace(/\n/g, '<br>')
//                                     .replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');
//     return formattedResponse;
// }

formatResponse(response:string) {
  // Replace \n with <br>, make text between ** bold, and convert URLs into links
  let formattedResponse = response
      .replace(/\n/g, '<br>')
      .replace(/\*\*(.*?)\*\*/g, '<b>$1</b>')
      .replace(/(https?:\/\/[^\s\)]+)/g, '<a href="$1" target="_blank">$1</a>');
  return formattedResponse;
} 
  
  scrollToBottom() {
    if (this.chatContainer && this.chatContainer.nativeElement) {
      this.chatContainer.nativeElement.scrollTop = this.chatContainer.nativeElement.scrollHeight;
    }
  }
  sendMessage(messageText: string) {
    this.loading = true;
    setTimeout(() => {
      this.messages.push({ text: messageText, type: 'user', timestamp: new Date() });
      this.loading = false;
    }, 500);  // Simulate a delay for loading
  }
 
  formatTimeAgo(timestamp: Date): string {
    return formatDistanceToNow(timestamp, { addSuffix: true });
  }
  ngAfterViewChecked() {
    this.scrollToBottom();
  }
  toggleCard() {
    this.showHelpCard = !this.showHelpCard;
  }

  // timeOutForClosingPopup(){
  //   setTimeout(()=>{
  //     this.showHelpCard = !this.showHelpCard;
  //   }, 10000)
  // }
}









