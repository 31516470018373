import { Component } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public onboarding = [];
  public transactionalData = [];
  // public onboarding = [
  //   { title: 'Dashboard', url: '/supplier-portal/dashboard', icon: 'mail' },
  //   { title: 'Onboarding Form', url: '/supplier-portal/vendor-request', icon: 'mail' },
  //   // { title: 'Customer Portal', url: '/folder/Outbox', icon: 'paper-plane' }
  // ];
  public master = [];
  user: any = {};
  permissions: any = {};
  currentUrl: any;
  url:any=['/payment-success','/payment-reject']

  Dashboard: any = { title: 'Dashboard', url: 'dashboard', icon: 'bar-chart' };
  Material: any = { title: 'Material', url: 'material/home-material', icon: 'cube' };
  Article: any = { title: 'Article', url: 'article/home-article', icon: 'document-text' };
  ArticleHierarchy:  any= { title: 'Article Hierarchy', url: 'article-hierarchy/home-article-hierarchy', icon: 'git-merge' };
  Customer: any = { title: 'Customer', url: 'customer/home-customer', icon: 'people' };
  Partner: any = { title: 'Partner', url: 'partner-home', icon: 'people' };
  Vendor: any = { title: 'Vendor', url: 'vendor/home-vendor', icon: 'person' };
  Gl: any = { title: 'GL', url: 'gl/home-gl', icon: 'list' };
  Routing: any = { title: 'Routing', url: 'routing/home-routing', icon: 'shuffle' };
  Site: any = { title: 'Site', url: 'site/home-site', icon: 'browsers' };
  ProfitCenter: any = { title: 'Profit Center', url: '/profit-center/home-profit-center', icon: 'cash' };
  CostCenter: any = { title: 'Cost Center', url: '/costCenter/home-cost-center', icon: 'pricetag' };
  Bom: any = { title: 'BOM', url: 'bom/home-bom', icon: 'receipt' };
  PR: any = { title: 'PR Creation', url: 'pr-creation', icon: 'receipt' };
  SalesOrder: any = { title: 'Sales Order', url: '/salesOrder/home-sales-order', icon: 'mail' }
  salesInvoice: any = { title: 'Customer Invoice', url: 'home-invoice', icon: 'receipt' }
  HTS: any = { title: 'HTS', url: '/hts-create', icon: 'receipt' }
    // { title: 'Sales Order Staging', url: 'salesOrderStaging/home-sales-order-staging', icon: 'paper-plane' }
  SupplierPortal: any = {dashBoard : { title: 'Dashboard', url: '/supplier-portal/dashboard', icon: 'bar-chart' },
                        dashBoardSupplier: { title: 'Dashboard', url: 'supplier-portal/dashboard-supplier', icon: 'bar-chart' },
                         vendorRequest: { title: 'Onboarding Form', url: '/supplier-portal/supplier-onboarding', icon: 'mail' },
                      }
  public labels = [];
  userId: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {
    let userId = localStorage.getItem('userId')

    if (userId) {
      this.authService.getUserDetails(JSON.parse(userId));
      // this.setupPermissions(userId);
      this.router.events.subscribe(event => {

        if (event instanceof NavigationEnd) {
          console.log(event.url);
          this.currentUrl = event.url;
          if (event.url == '/') {
            this.router.navigate(['/home'])
          }
        }
      });

      // this.router.events.subscribe((data:any) => {
      //   //console.log("this.router.url " , data.route)
      // })


      // this.router.navigate(['/home'])
    }
    else {
      this.router.navigate(['/login'])
    }

    // this.authService.updatePermissions.subscribe(r => {
    //   if (r)
    //     this.setupPermissions(JSON.parse(localStorage.getItem('userId')))
    // })

    this.authService.userDetails.subscribe(user => {

      if (user && Object.keys(user).length>0){
        this.user = user;
        this.setupPermissions(user)
        //console.log("users",this.user,this.authService.userType)
        if(user['updatePassword'] == true || this.authService.userType == 'organisation')
        {
          // if(this.currentUrl != '/settings/profile' && this.currentUrl != '/onboarding'){
          //   this.router.navigate(['/home'])
          // }
        }
        else{
          this.router.navigate(['/change-password'])
        }
      } 
    })

    this.authService.orgOnboarded.subscribe(accessRights => {
      this.grantPermissions(accessRights);
    })
  }

  showChatBot(): boolean {
    const currentRoute = this.router.url;
    return currentRoute === '/login' || currentRoute === '/register' || currentRoute === '/home';
  }

  setupPermissions(data) {
    // this.authService.getUserDetails(userId).then((data: any) => {
      this.master = [];
      if(data.userType)
      localStorage.setItem('userType', JSON.stringify(data.userType))
      if (data.organisationType == 'organisation') {
        if (data.onboarded){
          this.grantPermissions(data.accessRights);
          // if(this.currentUrl != '/settings/profile' && this.currentUrl != '/onboarding'){
          //   // this.router.navigate(['/home'])
          // }
        }
        else
        {
          this.router.navigate(['/onboarding'])
        }

      } else {
        let domainAndScreenPermissions = data.domainAndScreenPermissions;
        this.grantPermissions(domainAndScreenPermissions);
      }
    // })
  }

  grantPermissions(domainAndScreenPermissions){
    this.onboarding = [];
    this.transactionalData = []
    this.permissions = domainAndScreenPermissions;
    let keys = Object.keys(domainAndScreenPermissions);
    // this.master.push(this['Dashboard'])
    keys.forEach(key => {
      if (domainAndScreenPermissions[key].access == true && key != 'DMAC' && key!= 'SupplierPortal' && key!='SalesOrder')
        this.master.push(this[key]);
        //Added by Parul for making customise access for supplier portal
      if (domainAndScreenPermissions[key].access == true && key == 'SupplierPortal')
      {
        let supplierKeys = Object.keys(domainAndScreenPermissions[key].permission);
        console.log("supplierKeys",supplierKeys)
        supplierKeys.forEach(element => {
          if(domainAndScreenPermissions[key].permission[element] == true)
          this.onboarding.push(this[key][element])
        });
      }
      if (domainAndScreenPermissions[key].access == true && key == 'SalesOrder')
      {
          this.transactionalData.push(this[key])
        // this.onboarding.push(this[key][domainAndScreenPermissions[key].permission]) transactionalData
      }
    })
  }

  gotoHome() {
    this.router.navigate(['/home']);
  }

  logout() {
    localStorage.clear();
  }

}
